import React, { useContext, useEffect, useState } from 'react';
import { DarkModeContext } from '../context/darkModeContext';
import { getCurrentUserData } from '../auth';
import { RiDeleteBin2Line } from "react-icons/ri";
import { BsMoonStarsFill, BsSunFill } from "react-icons/bs";
import { TbMessageCircle2 } from "react-icons/tb";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import { VscFeedback } from "react-icons/vsc";
import { Link } from 'react-router-dom';
import { useUserData } from '../lib/userData';
import { logo2, userImage } from '../assets';
import Search from '../pages/Search';
import { useModal } from '../context/ModalContext';

const Navbar = () => {
  const { dispatch, darkMode } = useContext(DarkModeContext);
  const userData = useUserData();
  const { openModal } = useModal();
  const [isSubscriber, setIsSubscriber] = useState(localStorage.getItem('premiumSubscription') === 'subscriber');

  useEffect(() => {
    function handleStorageChange() {
      setIsSubscriber(localStorage.getItem('premiumSubscription') === 'subscriber');
    }

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return (
    <div className="w-full flex justify-between items-center p-5">
      <div className="w-full flex sm:hidden justify-between items-center">
        <div className='w-full flex justify-start'>
          <div className='flex justify-center items-center sm:p-1 h-[40px] sm:w-full flex-shrink-0'>
            <img alt="iTrood" src={logo2} className='h-[40px] max-sm:w-[120px] shrink-0 object-cover' />
          </div>
          <div className="sm:hidden cursor-pointer flex items-center mr-4">
            {darkMode ? (
              <BsSunFill
                className="icon text-lg"
                title='Light Mode'
                onClick={() => dispatch({ type: "TOGGLE" })}
              />
            ) : (
              <BsMoonStarsFill
                className="icon text-lg"
                title='Dark Mode'
                onClick={() => dispatch({ type: "TOGGLE" })}
              />
            )}
          </div>
        </div>
        <div className='w-[160px] flex align-center'>
          {!isSubscriber && (
            <div
              className={`cursor-pointer py-[6px] px-2 flex gap-2 justify-center items-center text-center mt-auto rounded-full animate-bounce ${darkMode ? "bg-[#ff0000] border hover:bg-[#ba0000] text-[#ffffff]" : "light-mode text-[#ffffff] bg-[#ff0000] hover:bg-[#c60000]"}`}
              onClick={openModal}
            >
              <div className='md:hidden text-sm'>Subscribe</div>
            </div>
          )}
          <Link to={userData?.id ? `/profile` : `/login`} className="cursor-pointer flex w-full justify-end">
            {/* <div className="ml-4 mr-2">
            {userData?.displayName || "Login"}
          </div> */}
            <img
              src={userData?.img || userImage}
              alt="iTrood"
              className="avatar w-8 h-8 rounded-full bg-slate-300 object-cover"
            />
          </Link>
        </div>
        {/* )} */}
      </div>
      <Search />
      <div className="max-sm:hidden items flex items-center max-sm:w-full max-sm:justify-between">
        {/* <Link to="/recyclebin" className="cursor-pointer flex items-center mr-4">
          <RiDeleteBin2Line size={20} className="icon text-lg mr-1" title='Recycle Bin' />
        </Link> */}
        <div className="cursor-pointer flex items-center mr-4">
          {darkMode ? (
            <BsSunFill
              className="icon text-lg"
              title='Light Mode'
              onClick={() => dispatch({ type: "TOGGLE" })}
            />
          ) : (
            <BsMoonStarsFill
              className="icon text-lg"
              title='Dark Mode'
              onClick={() => dispatch({ type: "TOGGLE" })}
            />
          )}
        </div>
        {/* <Link to="/feedbacks" className="cursor-pointer flex items-center mr-4 relative">
          <VscFeedback size={24} className="icon text-lg" />
          <div className="counter w-4 h-4 bg-blue-500 rounded-full flex items-center justify-center text-white text-xs font-bold absolute top-0 right-0">
            0
          </div>
        </Link> */}
        {/* <Link to="/notifications" className="cursor-pointer flex items-center mr-4 relative">
          <NotificationsNoneOutlinedIcon size={24} className="icon text-lg" />
          <div className="counter w-4 h-4 bg-red-500 rounded-full flex items-center justify-center text-white text-xs font-bold absolute top-0 right-0">
            12
          </div>
        </Link>
        <Link to="/messages" className="cursor-pointer flex items-center mr-4 relative">
          <TbMessageCircle2 size={24} className="text-lg" />
          <div className="counter w-2 h-2 bg-red-500 rounded-full flex items-center justify-center text-white text-xs font-bold absolute top-[2px] right-[1px]">
          </div>
        </Link> */}
        <Link to={userData?.id ? `/profile` : `/login`} className="cursor-pointer flex items-center">
          <div className="flex items-center ml-4 mr-2">
            {userData?.displayName || "Login"}
          </div>
          <img
            src={userData?.img || userImage}
            alt="iTrood"
            className="avatar w-8 h-8 rounded-full bg-slate-300 object-cover"
          />
        </Link>
      </div>
    </div>
  )
}

export default Navbar;